.chevronDown,
.chevronUp {
  width: 32px;
  height: 32px;
  @media screen and (max-width: 900px) {
	width: 24px;
	height: 24px;
  }
}

// .customSelectGroup{
//   .group-heading-wrapper + div{
//       display: none;
//   }

//   &.open{
//       .group-heading-wrapper + div{
//         display: block;
//       }
//   }
// }

.groupHeadingWrapper{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + div{
    display: none;
  }

  &.open + div{
    display: block;
  }

  &.open .verticalLine{
    display: none;
  }
}


.table{
  >tbody>tr{
    >td{
      border-top: 1px solid #C1C5C8
    }

    &:last-child{
      >td{
        border-bottom:1px solid #C1C5C8;
      }
     
    }

    &:last-child.noBorderBottom,
    &.noBorderBottom{
      >td{
        border-bottom: 0!important;
      }
    }
  }
}

.controlWrapper{
  width: 100%;
  @media (min-width:800px){
    max-width: 500px;
  }

}