.card{
    background-color: #fff;
    padding:15px;
    position: relative;
}

.card.popup{
    @media (min-width:800px){
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 80%;
        margin: 0 auto;
    }

    @media (min-width:1200px){
        &.popupFlowCard{
            width: 60%;
        }
    }
}

.detailImageWrapper{
    position:relative;
    width: 100%;
    padding-bottom: 75%;

    img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 500px;
    }
}

.closeButton{
    position: absolute;
    width: 22px;
    height: 22px;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;

    svg {
        overflow: visible;
    }
}

.rankLabel{
    background-color: #f7f7f7;
    color: var(--color-app-dark-gray);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    line-height: 1;
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 3px 10px;

    @media (min-width:800px){
        top: 0;
        right: 45px;
    } 
}

.rankNumber{
    font-size: 26px;
    margin-right: 4px;
    sup{
        font-size: 14px;
    }
}

.rankLabelBottom{
    font-size: 10px;
}

.countryName{
    display: flex;
    align-items: center;
}

.countryNameText{
    margin-left: 10px;
}

.value{
    font-size: 30px;
   
    @media (max-width:1800px){
        font-size: 22px;
    }

    @media (min-width:800px) and (max-width:991px){
        font-size: 17px;
    }
}

.hwni{
    font-size: 20px;
    line-height: 1;
    margin-bottom: 0;

    @media (max-width: 799px) {
        font-size: 16px;
        //margin-bottom: -15px;
        margin-bottom: auto;
    }
}

.countryImageWrapper {
    @media (min-width: 800px) {
        height: 100%;
        //padding-bottom: 10px;
    }
}

.shadowBackground {
    background-color: var(--color-app-henley-blue);
    svg{
        color: white!important;
    }
}

.card.wealthpopup{

    @media (max-width: 799px) {
        width: auto;
    }
    @media (min-width:800px){
        width: 70%;
        position: absolute;

        top: 50%;
        left: 50%;
        right: 0;
        transform: translate(-50%, -60%);
        margin: 0 auto;

    }

    @media (min-width:1200px){
        &.popupWealthFlowCard{
            width: 40%;
        }
    }
}

.col8p5{
    //flex: 0 0 70%;
    max-width: 70% !important;
    max-height: 30px;
    //margin-left: 15px;

    @media (max-width: 799px) {
        max-height: 60px;
    }
}

.col3p5{
    flex: 0 0 27%;
    max-width: 27%;
}
