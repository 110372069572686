.tableRow{
    &.cursor{
      cursor: pointer;
    }
   
    td{
        font-size: 17px;
        height: 60px;
        vertical-align: middle;
        padding: 0 15px;
        // border-top: 1px solid #C1C5C8;
        // border-bottom: 1px solid #C1C5C8;
        color: var(--color-app-henley-blue);
        font-family: Garamond Regular;

        @media (min-width:1200px){
          font-size: 26px;
        }

        &.nameCol{
          width: 15%;
        }
    
        &.barLeftCol{
          width: 15%;
          padding-right: 0;
          border-right: 1px solid #C1C5C8;
        }
    
        &.barRightCol{
          width: 55%;
          padding-left: 0;
        }
    
        &.valCol{
          width: 15%;
          text-align: right;
        }

        &.detailCol{
          // padding: 25px 30px;
          padding: 0 15px ;
          border-top: 0;
        }
    }

    &.tableRowHighlight{
      td{
        background-color: #fff;
      }
    }
}



.detailTable{
  td{
    padding-top: 15px;
    padding-bottom: 15px;
    vertical-align: top;
    height: auto;
    width: 100%;
    border-top:0;
    border-bottom: 0;

    @media (max-width:767px){
      display: block;
    }

    &.imageCol{
     
      @media (min-width:768px){
        width: 30%;
      }
    }

    &.countryInfo{
      padding: 25px 30px;
      @media (min-width:768px){
        width: 70%;
        border-left: 1px solid #C1C5C8;
      }
    }
  }
}

.compareButton{
  background-color: #C1C5C8;
  min-width: 200px;
  font-size: 10px;
  letter-spacing: 1.14px;
  line-height: 1.4;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  padding: 11px 0;
  border: 0;
  color: #fff;
  transition: all 0.25s ease-in-out;

  &:hover,
  &:focus,
  &:active{
    background-color: darken(#C1C5C8, 20%);
  }
}

.countryImageWrapper{
  position: relative;
  width: 100%;
  padding-bottom: 66.67%;
  img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}