.progressBarContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.progressBar{
  background: #DFE1E2;
  // border-top-right-radius: 18px;
  // border-bottom-right-radius: 18px;
  height: 30px;
  flex: 1;
  overflow: hidden;
  position: relative;
  // margin-left: 20px;
  // margin-right: 5px;

  .progress {
    background-color: #6FA6D8;
    height: 30px;
    position: absolute;
    left:0;
    top: 0;
    // border-top-right-radius: 18px;
    // border-bottom-right-radius: 18px;
    transition: width 0.5s ease-in-out;
  }

  &.directionLeft{
    border-top-right-radius: 0;   
    border-bottom-right-radius: 0;
    // border-top-left-radius: 18px;
    // border-bottom-left-radius: 18px;
    .progress{
      right: 0;
      left: auto;
      border-top-right-radius: 0;   
      border-bottom-right-radius: 0;
      // border-top-left-radius: 18px;
      // border-bottom-left-radius: 18px;
    }
  }
}



.labelText{
  flex: 0 0 auto;
  padding-left: 10px;
  min-width: 81px

}
  
  