$henley-blue: #405363;
$henley-gray: #75767A;
$secondary-gray: #c1d3e0;
$light-gray: #C5C6C8;
$gray: #6F7072;
$dark-gray: #4B4B4D;
$background-colour: #f7f7f7;
$white: #fff;

:root {
    --color-app-henley-blue: #{$henley-blue};
    --color-app-henley-gray: #{$henley-gray};
    --color-app-secondary-gray:#{$secondary-gray};
    --color-app-light-gray: #{$light-gray};
    --color-app-gray: #{$gray};
    --color-app-dark-gray: #{$dark-gray};
    --color-app-background-color: #{$background-colour};
    --color-app-white: #{$white};
}

[data-app-embed], [data-country-inflows-embed], [data-country-outflows-embed], [data-watchlist-embed], [data-mflows-embed], [data-top15-embed], [data-remains-embed] {
    scroll-behavior:smooth;
    background-color: #fff;

    .appContainer{
        // max-width: 1800px;
        margin: 0 auto;
    }
    .top-title{
        margin-top: 55px;
        margin-bottom: 30px;
    }
    table{

        th{
            font-size: 12px;
            color: var(--color-app-henley-blue);
        }
        
    }

    .react-tabs__tab-panel{
        padding:55px 0;
    }

    .menu-list{

        background-color: var(--color-app-white);
        
        @media (max-width: 767px){
            display: none;
        }

        .react-tabs__tab-list{
            
            max-width: 1800px;
            border-bottom: 0;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            @media (min-width:768px){
                padding:25px 15px;
            }

            @media (max-width:767px){
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 15px;
            }
        }

        .react-tabs__tab{
            padding: 0 25px;
            border: 0;
            margin-bottom: 0;
            border-radius: 0;
            @media (max-width:767px){
                padding:15px 0;
                // width: 100%;
                // text-align: center;
                border-bottom: 1px solid transparent;
            }
            &:before{
                border-bottom: 0;
                width: 1px;
                border-left: 1px solid var(--color-app-henley-gray);
                height:22px;
                top: 0;

                @media (max-width:767px){
                   display: none;
                }
            }

            span{
                display: inline-block;
                width: 100%;
                position: relative;

                @media (min-width: 768px){
                    border-bottom: 1px solid transparent;
                    padding-bottom: 10px;
                }
            }

            &:first-child{
                @media (min-width:768px){
                    padding-left: 0;
                    &:before{
                        border-left:0;
                        background-color: transparent;
                    }
                }
            }

            &.react-tabs__tab--selected{
             
                color: inherit;

                @media (max-width:767px){
                    border-bottom-color: var(--color-app-dark-blue);
                }
    
                span{
                    border-color: var(--color-app-dark-blue);
                }
            }

        }

    }

    .mobile-menu-wrapper{
        // position: sticky;
        // top: 0;
        // z-index: 10;
        @media (max-width:767px){
            padding: 0 15px;
            &.open{
                .menu-list{
                    display: block;
                }

                .dropdown-icon{
                    svg{
                        transform: rotate(-180deg);
                    }
                }
            }
        }
    }

    .mobile-menu-button{
        font-size: 14px;
        background-color: var(--color-app-henley-blue);
        color: var(--color-app-white);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        width: 100%;
        padding: 0;

        .dropdown-text{
            flex: 1;
            text-align: center;
            padding:8px;
        }

        .dropdown-icon{
            width: 43px;
            border-left: 2px solid var(--color-app-white);
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                transition: all 0.25s ease-in-out;
                width: 14px;
                height: auto;
            }
        }

        @media (min-width:768px){
            display: none;
        }

    }


    .custom-radiobox-group{
        display: flex;
        flex-direction: row;
        align-items: stretch;
        border:1px solid var(--color-app-henley-blue);
        padding:4px;
        background-color: #fff;
        justify-content: space-between;
        max-width: 320px;
    }

    .custom-radiobox{
        position: relative;
        display: block;
        font-size: 12px;
        letter-spacing: 1.14px;
        text-transform: uppercase;
        line-height: 1.4;
        margin-bottom: 0;
        color:var(--color-app-henley-blue);
        text-align: center;
        min-width: 84px;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        font-family: "Roboto Regular";



        input{
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .label-text{
           display: flex;
           padding: 12px 20px;
           cursor: pointer;
           justify-content: center;
           align-items: center;
           height: 100%;

           .circle{
               margin-right: 5px;
           }
        }

        &:hover input ~ .label-text {
            background-color: var(--color-app-background-color);
        }

        input:checked ~ .label-text{
            background-color: var(--color-app-henley-blue);
            color: #fff;
        }
          
    }

    .circle{
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        flex: 0 0 auto;

        &.circle-inflow{
            background-color: #7DB0DA;
        }

        &.circle-outflow{
            background-color: #D86F6F;
        }
    }

    .countryImageWrapper{
        position: relative;
        width: 100%;
        padding-bottom: 66.67%;
        @media (min-width: 800px){
            height: 100%;
            padding-bottom: 0;
        }
        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .text-underline{
        text-decoration: underline;
    }

    .footer-note{
        font-size: 12px;
        line-height: 1.2;
        color:  var(--color-app-dark-gray);
        font-family: Roboto Regular;
        margin-bottom: 50px;

        ul,li,p{
            font-size: inherit;
        }

        ul{
            list-style-type: disc;
            margin-left: 20px;
            li{
                margin-bottom: 2px;
                padding: 0;

                &:before{
                    display: none;
                }
            }
        }
    }

    h5.field{
        font-family: Roboto Regular;
        color: var(--color-app-dark-gray);
        text-transform: initial;
    }

    .chartTable{

        font-family: Garamond Regular;
        width: 100%;

        th{
            font-size: 12px;
            font-family: Roboto Regular;
             color: var(--color-app-henley-blue);
            vertical-align: bottom;
        }

        td{
            line-height: 1.1;
            // color: var(--color-app-henley-gray);
            border-right: 1px solid #C1C5C8;
            font-size: 20px;
            @media (max-width: 799px){
                font-size: 16px;
                // border-right: none;
                &:first-child{
                    border-right: 1px solid #C1C5C8;
                }
            }
        }

        th,
        td{
            border-bottom: 1px solid #C1C5C8;
            // padding: 10px 30px;
            padding: 10px;
            color: var(--color-app-henley-blue);

            &:first-child{
                padding-left: 0;
            }

            &:last-child{
                border-right: none;
            }
        }

    }

}

