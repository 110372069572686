.table{

    th,td{
        border-bottom: 1px solid #C1C5C8;
    }

    // th{
    //     font-size: 12px;
    //     color: var(--color-app-henley-blue);
    // }
    tr {
        td{
            font-size: 17px;
            height: 60px;
            line-height: 1.0;
            font-family: Garamond Regular;
            @media (min-width:1200px){
                font-size: 26px;
                // padding:16px 16px 16px 0;
            }

            &.yearColumn{
                color: var(--color-app-henley-gray);
                width: 100px;
            }

            &.valueColumn{
                color: var(--color-app-henley-blue);
            }

            &.barChartColumn{
                width: 75%;
            }
       
        }

        &.borderBottomDashed{
            td{
                border-bottom-style:dashed;
            }
        }
    }
}

// .migrationGraphTable{
//     th{
//         &:nth-child(2){
//             width: 60%;
//         }
//         &:last-child{
//             width: 94px;
//         }
//     }

//     @media (max-width:767px){
//         th:nth-child(3),
//         td:nth-child(3),
//         td:nth-child(4),
//         .notes{
//             display: none;
//         }
//     }
// }

// .migrationGraphTableMobile{
//     th:nth-child(2),
//     td:nth-child(2){
//         display: none;
//     }
// }

.barChartColumn{
    border-left: 1px solid #c1c5c8;
}

.notes{
    font-size: 10px;
    display: block;
    color: var(--color-app-henley-blue);
}

