.barChartWrapper{
    height: 18px;
    position: relative;
    overflow: hidden;

    &.toLeft{
 
       &,
       .progress{
            //border-top-left-radius: 10px;
            //border-bottom-left-radius: 10px;
       }

       .progress{
           right: 0;
       }
    }

    &.toRight{
        &,
        .progress{
            //border-top-right-radius: 10px;
            //border-bottom-right-radius: 10px;
        }

        .progress{
            left: 0;
        }
    }
}

.progress{
    position: absolute;
    top: 0;
    height: 12px;
    width: 0;
    transition: width 0.25s ease-in-out;
}